[vlx=hero] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  overflow: hidden;
}
[vlx=hero] [vlx=hero__image] {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: url("../assets/hero.png");
  background-size: cover;
  background-position: bottom;
}
[vlx=hero] [vlx=hero__image]::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
}
[vlx=hero] [vlx=hero__text] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-shadow: 0 0 2px rgba(0,0,0,0.5);
}
[vlx=hero] [vlx=hero__text] [vlx=hero__text__title] {
  font-size: 5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
}
[vlx=hero] [vlx=hero__text] [vlx=hero__text__description] {
  font-size: 2rem;
  color: #fff;
}
[vlx=row__1] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
[vlx=row__1] .column {
  flex: 1 0 20%;
  padding: 1em;
}
[vlx=row__1] .column h3 {
  content: normal;
}
[vlx=row__1] .column p {
  content: normal;
}
