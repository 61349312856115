[vlx="hero"] {
  justify-content: center;
  align-items: center;
  height: 400px;
  display: flex;
  position: relative;
  overflow: hidden;
}

[vlx="hero"] [vlx="hero__image"] {
  z-index: 1;
  background-image: url("hero.6c19634f.png");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

[vlx="hero"] [vlx="hero__image"]:after {
  content: "";
  z-index: 1;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

[vlx="hero"] [vlx="hero__text"] {
  z-index: 2;
  text-align: center;
  text-shadow: 0 0 2px #00000080;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[vlx="hero"] [vlx="hero__text"] [vlx="hero__text__title"] {
  color: #fff;
  margin-bottom: 0;
  font-size: 5rem;
  font-weight: bold;
}

[vlx="hero"] [vlx="hero__text"] [vlx="hero__text__description"] {
  color: #fff;
  font-size: 2rem;
}

[vlx="row__1"] {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

[vlx="row__1"] .column {
  flex: 1 0 20%;
  padding: 1em;
}

[vlx="row__1"] .column h3, [vlx="row__1"] .column p {
  content: normal;
}
/*# sourceMappingURL=index.c8d71940.css.map */
